import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Layout from "../components/layout";
import "../styles/page/about-us.css";
import { Link } from "gatsby";
import diagram from "images/Group 2247.png";
import diagrammb from "images/Group 2378.png";
import BlockIcon from "../components/homepage/block-icon";
const aboutus = () => {
  const { strapiAboutUs, allStrapiPartner } = useStaticQuery(graphql`
    query {
      allStrapiPartner {
        nodes {
          category
          title
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80
                  webpOptions: {quality: 80}
                  placeholder: BLURRED)
              }
            }
          }
        }
      }
      strapiAboutUs {
        title
        description
        DirectorImages {
          id
          DirectorImages {
            image {
              alternativeText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80
                    webpOptions: {quality: 80}
                    placeholder: BLURRED)
                }
              }
            }
            description
            title
            id
          }
        }
        Banner {
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80
                  webpOptions: {quality: 80}
                  placeholder: BLURRED)
              }
            }
          }
          title
        }
        listIcons {
          listIcons {
            image {
              url
              alternativeText
              localFile {
                id
                url
                childImageSharp {
                  gatsbyImageData(quality: 80
                    webpOptions: {quality: 80}
                    placeholder: BLURRED) 
                }
              }
            }
            title
            description
            id
          }
        }
        strongPoints {
          listIcons {
            image {
              url
              alternativeText
              localFile {
                url
                id
              }
            }
            title
            description
            id
          }
        }
      }
    }
  `);

  const bankPartner = allStrapiPartner.nodes.filter(
    (item) => item.category === "bank"
  );
  const investorPartner = allStrapiPartner.nodes.filter(
    (item) => item.category === "investor"
  );
  const retailPartner = allStrapiPartner.nodes.filter(
    (item) => item.category === "retail"
  );
  return (
    <Layout>
      <GatsbySeo title="Về Chúng tôi" />
      <div className="about">
          <div>
            <GatsbyImage image={strapiAboutUs.Banner.image.localFile.childImageSharp.gatsbyImageData} alt={strapiAboutUs.Banner.image.alternativeText} className="project__baner"/>
            <div className="container">
              <div className="baner__slider Home__siteTitle">
                <div className="baner__pd">
                  <Link title="Trang-chu" to="/" className="baner__title">
                    Trang Chủ
                  </Link>{" "}
                  / <span className="baner__title">{strapiAboutUs.Banner.title}</span>
                </div>
                <h2 className="baner__desc">{strapiAboutUs.Banner.title}</h2>
              </div>
            </div>
          </div>
      </div>
      <div className="container">
        <div className="about__inner">
          <div className="about__inner__text home__siteTitle">
            <h2>{strapiAboutUs.title}</h2>
          </div>
          <div className="about__inner__desc">
              {strapiAboutUs.description}
          </div>
        </div>
      </div>
      <div className="about__Block">
        <div className="container py-6">
          <div className="about__targetItem">
            <ul className="targetItem__row">
              {strapiAboutUs.listIcons.listIcons.map((item, id) => (
                <li key={id}>
                  <GatsbyImage
                    image={item.image.localFile.childImageSharp.gatsbyImageData}
                    className="targetItem__icon"
                    alt={item.image.alternativeText}
                  />
                  <h5>{item.title}</h5>
                  <p>{item.description}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="about__strength">
          <div className="about__strength__title home__siteTitle">
            <h2>Điểm Mạnh của Alphaland</h2>
          </div>
          <div className="about__strength__row">
            <ul>
              {strapiAboutUs.strongPoints.listIcons.map((item, id) => (
                <li key={id}>
                  <div className="about__strength__icon">
                    <img src={item.image.localFile.url} alt={item.image.alternativeText}/>
                  </div>
                  <p>{item.title}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="about__flex">
          <div className="about__flex__title home__siteTitle">
            <h2>ban điều hành alpha group</h2>
          </div>
          <div>
            <div className="Operating">
              {strapiAboutUs.DirectorImages.DirectorImages.map((item, id) => (
                <div key={id} className="Operating__row">
                  <div className="Operating__image">
                    <GatsbyImage image={item.image[0].localFile?.childImageSharp?.gatsbyImageData} className="Operating__img" alt={item.image[0].alternativeText}/>
                  </div>
                  <div  className="about__flex__row">
                    <h4 className="Operating__title">{item?.title}</h4>
                    <p>{item?.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="about__diagram">
        <div className="home__recruitmentBlock home__siteTitle">
          <div className=" about__diagram-title container py-6">
            <h2 className="mgbt pdt">SƠ ĐỒ TỔ CHỨC ALPHAGROUP</h2>
            <div className="about__diagram-pc">
              <img src={diagram} className="img__projectBlockRG" />
            </div>
            <div className="about__diagram-mb">
              <img src={diagrammb} className="img__projectBlockRG" />
            </div>
          </div>
        </div>
      </div>
      <div className="about__center">
        <div className="home__siteTitle container">
          <h2>ĐỐI TÁC CHIẾN LƯỢC</h2>
        </div>
        <div className="about__partner">
          <Tabs variant="soft-rounded" colorScheme="green">
            <TabList
              sx={{ justifyContent: "center" }}
              className="about__strategy__tablist"
            >
              <Tab
                sx={{
                  color: "#B2B2B2",
                  borderRadius: "4px",
                  borderWidth: "1px",
                  borderColor: "#B2B2B2",
                  boxShadow: "1px",
                  margin: "0px 10px",
                  padding: "10px",
                  fontSize:"12px",
                  marginTop:"10px"
                }}
                _selected={{
                  color: "white",
                  bg: "#f58420",
                  borderRadius: "4px",
                  borderColor: "#f58420",
                  margin:"0 6px",
                  padding:"10px",
                  fontSize:"12px",
                  marginTop:"10px"
                }}
                _focus={{ boxShadow: "none" }}
              >
                CÁC CHỦ ĐẦU TƯ
              </Tab>
              <Tab
                sx={{
                  color: "#B2B2B2",
                  borderRadius: "4px",
                  borderWidth: "1px",
                  borderColor: "#B2B2B2",
                  boxShadow: "1px",
                  margin:"0 6px",
                  padding:"10px",
                  fontSize:"12px",
                  marginTop:"10px"
                }}
                _selected={{
                  color: "white",
                  bg: "#f58420",
                  borderRadius: "4px",
                  borderColor: "#f58420",
                  margin:"0 6px",
                  padding:"10px",
                  fontSize:"12px",
                  marginTop:"10px"
                }}
                _focus={{ boxShadow: "none" }}
              >
                NGÂN HÀNG
              </Tab>
              <Tab
                sx={{
                  color: "#B2B2B2",
                  borderRadius: "4px",
                  borderWidth: "1px",
                  borderColor: "#B2B2B2",
                  boxShadow: "1px",
                  padding: "10px",
                  margin:"0 6px",
                  padding:"10px",
                  fontSize:"12px",
                  marginTop:"10px"
                }}
                _selected={{
                  color: "white",
                  bg: "#f58420",
                  borderRadius: "4px",
                  borderColor: "#f58420",
                  margin:"0 6px",
                  padding:"10px",
                  fontSize:"12px",
                  marginTop:"10px"
                }}
                _focus={{ boxShadow: "none" }}
              >
                ĐỐI TÁC PHÂN PHỐI
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel id="strategy">
                <div className="container">
                  <div className="strategy__row flex">
                  {investorPartner.map((invimg, key) => (
                      <div className="sliderrowss" key={key}>
                          <GatsbyImage image={invimg.image.localFile.childImageSharp.gatsbyImageData} className="strategy__aboutImg" alt={invimg.image.alternativeText}/>
                      </div>
                    ))}
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="container">
                  <div className="strategy__row flex">
                    
                    {bankPartner.map((Bankimg, key) => (
                      <div className="sliderrowss" key={key}>
                           <GatsbyImage image={Bankimg.image.localFile.childImageSharp.gatsbyImageData} className="strategy__aboutImg" alt={Bankimg.image.alternativeText}/>
                      </div>
                    ))}
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="container">
                  <div className="strategy__row flex">
                    {retailPartner.map((Bankimg1, key) => (
                      <div className="sliderrowss" key={key}>
                         <GatsbyImage image={Bankimg1.image.localFile.childImageSharp.gatsbyImageData} className="strategy__aboutImg" alt={Bankimg1.image.alternativeText}/>  
                      </div>
                    ))}
                  </div>
                </div>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </div>
      </div>
      <BlockIcon/>
    </Layout>
  );
};

export default aboutus;
